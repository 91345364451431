export enum UiEntityPropertyTypeEnum {
  DATE = 'date',
  CUSTOM = 'custom',
  BOOLEAN = 'boolean',
  STRING = 'string',
  NUMBER = 'number',
  CURRENCY_USD = 'currency_usd',
  PRO_NUMBER = 'pro_number',
  PRO_NUMBER_OR_SEQ_NBR = 'pro_number_or_seq_nbr',
  NUMBER_NA_IF_ZERO = 'number_na_if_0',
}
